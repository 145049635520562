@import "../../colors";


@mixin clusterColor($col) {
  h1, h2 {
    color: $col;
  }

  .desc {
    background-color: rgba($col, .8);;
  }

  .scrollCont::-webkit-scrollbar-thumb {
    background-color: $col;
  }

}

.layer {
  &.orga {
    @include clusterColor($colorOrga)
  }


  &.tech {
    @include clusterColor($colorTech)
  }


  &.culture {
    @include clusterColor($colorCulture)
  }
}


.layer {
  position: absolute;
  top: 100%;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .9);
  color: #ffffff;
  overflow: hidden;
  z-index: 10;


  .scrollCont {
    position: absolute;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    width: 100%;

    &::-webkit-scrollbar {
      width: 10px;
      height: 8px;
      background-color: #000000;
    }


    &::-webkit-scrollbar-thumb {
      width: 10px;
    }
  }


  p.desc {
    font-size: 1.2em;
    padding: 2em;
    box-sizing: border-box;
    margin-bottom: 2em;

    background-color: rgba(18, 18, 18, 0.9);
  }


  .pageContainer {
    max-width: 1024px;
    margin: 0 auto;
  }

  .itemCard {
    background-color: rgba(18, 18, 18, 0.90);
    padding: 2em;
    margin-bottom: 2em;
  }

  h1 {
    font-size: 3em;
    line-height: 3em;

    padding: 0;
    margin: 0;

  }

  h2 {
    line-height: 1.2em;
    font-size: 2em;
    padding: 0;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  h3 {
    line-height: 1.8em;
    font-size: 1.8em;
  }
}


p {
  line-height: 1.4em;
}


.layerControls {
  position: absolute;
  top: 25px;
  right: 35px;
}


.layerHeader {
  padding-top: 8em;
  padding-bottom: 10em;

  h1 {
    font-size: 8em;
    line-height: 1em;
    text-shadow: -3px 6px 7px #00000057;
    //background-color: rgba(0, 0, 0, 0.2);
    display: inline-block;
    font-family: Menlo, Monaco, "Courier New", Courier, monospace;
    text-transform: uppercase;
  }

  pre {
    background-color: #000000;
    display: inline-block;
    padding: .2em;
    margin-left: .2em;
  }
}

.layerBackground {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;

  .layerImg {
    width: 100vw;
    height: 100vh;
    background-size: cover;
  }
}


