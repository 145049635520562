.glitchText {
  color: #ffffff;
  font-size: 4em;
  position: relative;
  height: 55px;
  width: 100%;
  white-space: nowrap;
  font-family: Menlo, Monaco, "Courier New", Courier, monospace;

  .text {
    position: absolute;
    text-align: center;
    width: 100%;
    text-transform: uppercase;

  }

  .top {
    clip-path: inset(0 0 41%);
  }

  .bottom {
    clip-path: inset(58% 0 0 0);
  }
}
