$side1: #55b511;
$side2: #098bb3;
$side3: #f19b07;
$pureBlue: #00adef;

$color1: #f19b07;

$color2: #40b2bc;
$color3: #3D9BCD;


@font-face {
  font-family: 'DaimlerRegular';
  src: local('MyFont'), url(fonts/daimler-cs-regular-woff.woff) format('woff');
}

@font-face {
  font-family: 'DaimlerItalic';
  src: local('MyFont'), url(fonts/DaimlerCS-DemiItalic.woff) format('woff');
}

html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: 'DaimlerRegular', Verdana, sans-serif;
}

html {
  font-size: 32px;
}

body {
  box-sizing: content-box;
  font-size: 14px;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
  @media (min-width: 1280px) {
    font-size: 18px;
  }
  @media (min-width: 1440px) {
    font-size: 19px;
  }

  @media (min-width: 1800px) {
    font-size: 21px;
  }

}


#root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.appContainer {
  background-color: #333333;;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.canvasCont {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}


h1 {
  //background-color: #ffffff;
  font-size: 2em;
  //padding: .1em .2em;
  //margin: 0;
  font-family: 'DaimlerRegular';

  &.sub {
    margin-top: .2em;
    font-size: 1.4em;
  }

  &.huge {
    font-size: 3em;
  }
}

h2 {
  font-weight: normal;
  padding-bottom: .8em;
  padding-top: 1em;
  //color: $pureBlue;
  font-size: 1.2em;
  margin: 0;
}

h3 {
}

//Colors
.side {
  &.color1 {
    h1, .kpi .content .value {
      color: $color1;
    }

    h1 {
      color: $color1;
    }

    .bullet .icon svg, .kpi .icon svg {
      fill: $color1;
    }
  }

  &.color2 {
    h1, .kpi .content .value {
      color: $color2;
    }

    h1 {
      color: $color2;
    }

    .bullet .icon svg, .kpi .icon svg {
      fill: $color2;
    }

    h1 {
      color: $color2;
    }
  }

  &.color3 {
    h1, .kpi .content .value {
      color: $color3;
    }

    .bullet .icon svg, .kpi .icon svg {
      fill: $color3;
    }

    h1 {
      color: $color3;
    }
  }
}

div {
  box-sizing: border-box;
}


.contentWrp {
  position: relative;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 40px;
  pointer-events: none;
}

.contentCont {
  position: relative;

  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  color: #ffffff;
  pointer-events: none;
  font-size: 1em;
  font-family: 'DaimlerRegular';
  box-sizing: border-box;

  //@media (min-planeWidth: 1024px) {
  //  left: 40px;
  //  right: 40px;
  //}
  .logo {
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: pointer;
    pointer-events: auto;
  }


  .side {
    display: flex;
    flex-flow: column;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, .1);
    padding: 1em;
    height: 100vh;
    box-sizing: border-box;


    @media (min-width: 1024px) {
      flex-flow: row;
      background-color: rgba(0, 0, 0, 0);
      height: 100%;
    }

    p {
      max-width: 520px;
      line-height: 1.4em;
    }


    h2 {
      font-family: monospace;
      font-size: 1em;
    }

    .text {
      display: flex;
      flex-flow: column;
      justify-items: left;
      align-items: flex-start;
      width: 100%;

      h2 {
        text-shadow: 2px 2px #0000008a;

      }
    }

    .data {
      width: 50%;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      margin-top: 1em;

      @media (min-width: 1024px) {
        margin-top: 71px;
        align-items: flex-end;
      }

    }
  }


}


.bullets {
  padding-top: .5em;

  .bullet {
    display: flex;
    align-items: center;
    font-size: 1em;
    margin-bottom: .2em;
    text-shadow: 2px 2px #0000008a;

    .icon {
      width: 1.5em;
      height: 1.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: .5em;
      flex-shrink: 0;

      svg {
        fill: $pureBlue;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.kpis {
  position: relative;
  right: 0;


  .kpi {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-bottom: 1em;
    opacity: 0;

    .icon {
      width: 2.5em;
      height: 2.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      position: relative;

      .shadow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        svg {
          fill: white;
        }
      }


      svg {
        //fill: $pureBlue;
        width: 100%;
        height: 100%;
      }
    }

    .content {
      display: flex;
      flex-flow: column;
      width: 250px;
      margin-left: 1em;
      text-shadow: 2px 2px #0000008a;

      .value {
        color: $pureBlue;
        font-size: 1.4em;
      }

      .caption {

      }
    }
  }
}


#debug {
  position: absolute;
  bottom: 10px;
  display: none;
}

.controls {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}


.closeBtn {
  width: 64px;
  height: 64px;
  background-color: rgba(0, 0, 0, .6);
  color: white;
  cursor: pointer;
}

.videoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;


  //video {
  //  position: absolute;
  //  planeWidth: 100%;
  //  planeHeight: auto;
  //}
}


.svgIcon {
  position: relative;
  overflow: hidden;

  svg {
    width: 100%;
    height: 100%;
  }
}

.logo {
  //position: absolute;
  //bottom: 5px;
  //left: 40px;
  //pointer-events: pointer;


  img {
    width: 30px;
  }
}

.colorLegend {

  position: absolute;
  bottom: 10px;
  right: 40px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    img {
      width: 48px;
      padding-right: .5em;
    }

    .caption {
      h5 {
        font-size: .8em;
        padding: 0;
        margin: 0
      }

      p {
        padding: 0;
        margin: 0;
        font-size: .7em;
      }
    }
  }


}

.partner {
  position: absolute;
  bottom: 0;
  left: 1em;
  width: 450px;

  .logos {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .pLogo {
      flex-basis: auto;
      width: 100px;
      flex-shrink: 1;
      padding: 5px;

      img {
        max-width: 100%;
      }
    }
  }
}


.appLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;

  .powerdBy {
    color: white;
    position: absolute;
    bottom: .5em;
    font-size: .8em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;

    .logo {
      padding-top: .5em;
    }
  }

  .button {
    align-self: flex-end;
    opacity: 0;

    svg {
      fill: #ffffff;
      pointer-events: none;
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000000;
  }

  .inlay {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 550px;

    h1 {
      color: $color3;
      margin-bottom: 1em;
      font-size: 2.5em;
    }

    p {
      color: white;
      padding: .5em;
      margin: 0;
    }


    .ani {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .text {
        display: flex;
        justify-content: center;
        margin-bottom: 2em;

        p {
          position: absolute;
          opacity: 0;
          text-align: center;
        }
      }
    }


    .mouseIcon {
      fill: white;
      opacity: 0;
      height: 48px;
    }
  }
}

.button {
  background-color: #ff00aa;
  padding: .5em;
  cursor: pointer;
  margin-top: 2em;
  color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;

  svg {
    fill: #ffffff;
    opacity: 1;
    height: 20px;
    width: 20px;
    padding-left: 1em;
    padding-right: .5em;
  }
}

.side.intro {
  .button {
    color: #000000;
  }
}

.typewriter {
  width: 400px;
  font-size: 1em;
  color: #ffffff;
  line-height: 1.6em;
}


pre {
  margin: 0;
}
